const keyCodes = {
    48: 'N0', // 0
    41: ')', // )

    33: '!', // !
    49: 'N1', // 1

    50: 'N2', // 2
    64: '@', // @

    35: '#', // #
    51: 'N3', // 3

    52: 'N4', // 4
    36: '$', // $

    53: 'N5', // 5
    37: '%', // %

    54: 'N6', // 6
    94: '^', // ^

    55: 'N7', // 7
    38: '&', // &

    56: 'N8', // 8
    42: '*', // *

    57: 'N9', // 9
    40: '(', // (

    126: '~', // ~
    96: '`', // `

    44: ',', // ,
    60: '<', // <

    62: '>', // >
    46: '.', // .

    47: '/', // /
    63: '?', // ?

    58: ':', // :
    59: ';', // ;

    34: '"', // "
    39: '\'', // '

    91: '[', // [
    123: '{', // {

    93: ']', // ]
    125: '}', // }

    92: '\\', // \
    124: '|', // |

    65: 'A',
    66: 'B',
    67: 'C',
    68: 'D',
    69: 'E',
    70: 'F',
    71: 'G',
    72: 'H',
    73: 'I',
    74: 'J',
    75: 'K',
    76: 'L',
    77: 'M',
    78: 'N',
    79: 'O',
    80: 'P',
    81: 'Q',
    82: 'R',
    83: 'S',
    84: 'T',
    85: 'U',
    86: 'V',
    87: 'W',
    88: 'X',
    89: 'Y',
    90: 'Z',

    32: '␣',
    13: '˩',
    61: '=',
    43: '=',
    45: '-',
    95: '-',
}

export {keyCodes as default}