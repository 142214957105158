const XBOX = {
    0:"A",
    1:"B",
    2:"X",
    3:"Y",
    4:"LB",
    5:"RB",
    6:"LT",
    7:"RT",
    8:"⛒",
    9:"⚌",
    10:"LS",
    11:"RS",
    12:"UP",
    13:"DOWN",
    14:"LEFT",
    15:"RIGHT",
    16:"⛌",
}
const PSC={
    0:"X",
    1:"O",
    2:"□",
    3:"△",
    4:"L1",
    5:"R1",
    6:"L2",
    7:"R2",
    8:"⚇",
    9:"⚌",
    10:"LS",
    11:"RS",
    12:"UP",
    13:"DOWN",
    14:"LEFT",
    15:"RIGHT",
    16:"⛌",
}
export {XBOX,PSC}