
const skillcheckSpawnCoordinates = {
    greatSkillCheckCoordinates: {
        start: 0,
        end: 0
    },
    goodSkillCkeckCoordinates: {
        start: 0,
        end: 0
    },
    mode: ''
}


export {skillcheckSpawnCoordinates}

